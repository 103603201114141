import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

type CardImageHeaderViewProps = {
  image: string;
  imageVerticalOffset: number;
  loading: boolean;
  collapsed: boolean;
  readonly?: boolean;
  onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
  onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => void;
  imageDeleteHandler?: () => void;
  offSetHandler?: (offset: number) => void;
  deleteHandler: () => void;
};

const CardImageHeaderView: React.FC<CardImageHeaderViewProps> = ({
  image,
  imageVerticalOffset,
  loading,
  collapsed,
  readonly,
  onMouseDown,
  onTouchStart,
  offSetHandler,
  imageDeleteHandler,
  deleteHandler,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Collapse in={collapsed}>
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            marginTop: -2,
            marginLeft: -2,
            marginRight: -2,
            marginBottom: 2,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflow: "hidden",
          }}
        >
          <CardMedia
            component="img"
            height="100"
            image={image}
            alt="Goal Image"
            sx={{
              cursor: readonly ? "default" : "ns-resize",
              objectFit: "cover",
              objectPosition: "50% " + imageVerticalOffset + "%",
            }}
            onMouseDown={readonly ? undefined : onMouseDown}
            onTouchStart={readonly ? undefined : onTouchStart}
          />
          {loading ? (
            <Typography
              variant="body2"
              align="center"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Loading...
            </Typography>
          ) : null}
        </Box>
        {imageDeleteHandler && image && !readonly ? (
          <IconButton
            aria-label="delete-image"
            onClick={deleteHandler}
            sx={{
              position: "absolute",
              zIndex: 2,
              top: 0,
              right: 0,
              color: "primary.main",
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                color: "error.main",
              },
            }}
          >
            <Delete />
          </IconButton>
        ) : null}
      </Collapse>
    </Box>
  );
};

export default CardImageHeaderView;
