import { auth } from "../../../config/firebase";
import { Page, Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { signOut } from "firebase/auth";
import UserProfileMenuView from "./UserProfileMenuView";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

type UserProfileMenuControllerProps = {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLElement;
};

const UserProfileMenuController: React.FC<UserProfileMenuControllerProps> = ({
  open,
  onClose,
  anchorEl,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate(Page.USER_PROFILE);
  };

  const logoutHandler = () => {
    signOut(auth)
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Successfully logged out",
            severity: Severity.SUCCESS,
          })
        );
        navigate(Page.SIGN_IN);
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error instanceof Error ? error.message : "An error occurred while logging out",
            severity: Severity.ERROR,
          })
        );
      });
  };

  return (
    <UserProfileMenuView
      open={open}
      onClose={onClose}
      onProfileClick={handleProfileClick}
      onLogout={logoutHandler}
      anchorEl={anchorEl}
    />
  );
};

export default UserProfileMenuController;
