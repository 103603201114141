import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

type AddBuddyButtonProps = {
  onClick: () => void;
};

const AddBuddyButton: React.FC<AddBuddyButtonProps> = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      startIcon={<Add />}
    >
      Add buddy
    </Button>
  );
};

export default AddBuddyButton;
