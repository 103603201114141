import PageContainer from "../components/layout/PageContainer";
import WizardController from "../components/onboarding/wizard/OnboardingWizardContoller";

const Onboarding = () => {
  return (
    <PageContainer>
      <WizardController />
    </PageContainer>
  );
};

export default Onboarding;
