import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import DeleteButton from "../../layout/DeleteButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingSpinner from "../../layout/LoadingSpinner";
import NoResults from "../../layout/NoResults";
import React from "react";
import { SharedUser } from "@beyondrealityapp/core/user/types";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

const moduleDisplayNames: { [key: string]: string } = {
  goals: "Goals",
  journal: "Journal",
  reviews: "Reviews",
  themes: "Themes",
  coreValues: "Core values",
  personalityTests: "Personality tests",
  adminPanel: "Admin panel",
  settings: "Settings",
  journalEntry: "Journal entry",
  reviewDetails: "Review details",
  goalSetter: "Goal setter",
  home: "Home",
  signIn: "Sign in",
  signUp: "Sign up",
  awaitingVerification: "Awaiting verification",
};

type SharedWithOthersViewProps = {
  buddies: SharedUser[];
  isFetchingUser: boolean;
  fetchUserError: string;
  onRemoveBuddyClick: (userId: string) => void;
  isRemovingBuddy: boolean;
};

const SharedWithOthersView: React.FC<SharedWithOthersViewProps> = ({
  buddies,
  isFetchingUser,
  fetchUserError,
  onRemoveBuddyClick,
  isRemovingBuddy,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 2,
      }}
    >
      {fetchUserError && <Typography>{fetchUserError}</Typography>}
      {isFetchingUser && !fetchUserError && <LoadingSpinner />}
      {!isFetchingUser && buddies.length === 0 && (
        <NoResults message="You haven't added any buddies yet. Enter an e-mailadress below to add a buddy." />
      )}
      {!isFetchingUser &&
        buddies.length > 0 &&
        buddies.map((buddy) => (
          <Accordion key={buddy.uid}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {buddy.profilePicture ? (
                  <Avatar src={buddy.profilePicture} />
                ) : (
                  <Avatar>{buddy.name[0]}</Avatar>
                )}
                <Box
                  sx={{
                    marginLeft: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>{buddy.name || "No name available"}</Typography>
                  <Typography variant="body2" color="GrayText">
                    {buddy.email || "No email available"}
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Module</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Access</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(buddy.allowedModules).map(
                    ([module, access]) => (
                      <TableRow key={module}>
                        <TableCell>{moduleDisplayNames[module]}</TableCell>
                        <TableCell>
                          <Tooltip title="Fine grained access control is not yet implemented">
                            <span>
                              <Switch
                                checked={access}
                                color="primary"
                                disabled
                              />
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <DeleteButton
                  deleteHandler={() => onRemoveBuddyClick(buddy.email)}
                  deleting={isRemovingBuddy}
                  text="Remove buddy"
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};

export default SharedWithOthersView;
