import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import MenuHeader from "../MenuHeader";
import MenuItem from "../MenuItem";
import { MenuItemType } from "@beyondrealityapp/core/shared/types";

type MenuViewProps = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  checkIfActiveMenuItems: (menuItems: MenuItemType[]) => boolean;
  knowYourselfMenuItems: MenuItemType[];
  defineYourselfMenuItems: MenuItemType[];
  becomeYourselfMenuItems: MenuItemType[];
  communityMenuItems: MenuItemType[];
  settingsMenuItems: MenuItemType[];
  showAdminPanel: boolean;
  adminPanelMenuItems: MenuItemType[];
};

const MenuView: React.FC<MenuViewProps> = ({
  open,
  onClose,
  onOpen,
  checkIfActiveMenuItems,
  knowYourselfMenuItems,
  defineYourselfMenuItems,
  becomeYourselfMenuItems,
  communityMenuItems,
  settingsMenuItems,
  showAdminPanel,
  adminPanelMenuItems,
}) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <Box>
          <MenuHeader onClose={onClose} />
          <Divider />
        </Box>
        <List>
          {checkIfActiveMenuItems(knowYourselfMenuItems) && (
            <>
              <ListSubheader>Know Yourself</ListSubheader>
              {knowYourselfMenuItems.map(
                (menuItem) =>
                  menuItem.active && (
                    <MenuItem
                      key={menuItem.text}
                      text={menuItem.text}
                      icon={menuItem.icon}
                      path={menuItem.path}
                      onClose={onClose}
                    />
                  )
              )}
            </>
          )}
          {checkIfActiveMenuItems(defineYourselfMenuItems) && (
            <>
              <Divider />
              <ListSubheader>Define yourself</ListSubheader>
              {defineYourselfMenuItems.map(
                (menuItem) =>
                  menuItem.active && (
                    <MenuItem
                      key={menuItem.text}
                      text={menuItem.text}
                      icon={menuItem.icon}
                      path={menuItem.path}
                      onClose={onClose}
                    />
                  )
              )}
            </>
          )}
          {checkIfActiveMenuItems(becomeYourselfMenuItems) && (
            <>
              <Divider />
              <ListSubheader>Become yourself</ListSubheader>
              {becomeYourselfMenuItems.map(
                (menuItem) =>
                  menuItem.active && (
                    <MenuItem
                      key={menuItem.text}
                      text={menuItem.text}
                      icon={menuItem.icon}
                      path={menuItem.path}
                      onClose={onClose}
                    />
                  )
              )}
            </>
          )}
          {checkIfActiveMenuItems(communityMenuItems) && (
            <>
              <Divider />
              <ListSubheader>Community</ListSubheader>
              {communityMenuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.text}
                  text={menuItem.text}
                  icon={menuItem.icon}
                  path={menuItem.path}
                  onClose={onClose}
                />
              ))}
            </>
          )}
          {checkIfActiveMenuItems(settingsMenuItems) && (
            <>
              <Divider />
              {settingsMenuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.text}
                  text={menuItem.text}
                  icon={menuItem.icon}
                  path={menuItem.path}
                  onClose={onClose}
                />
              ))}
            </>
          )}
          <Divider />
          <>
            <ListSubheader>Site controls</ListSubheader>
            {showAdminPanel &&
              adminPanelMenuItems.map((menuItem) => (
                <MenuItem
                  key={menuItem.text}
                  text={menuItem.text}
                  icon={menuItem.icon}
                  path={menuItem.path}
                  onClose={onClose}
                />
              ))}
          </>
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default MenuView;
