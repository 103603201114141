import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";

type AddBuddyType = {
  email: string;
};

type AddBuddyModalProps = {
  formik: FormikProps<AddBuddyType>;
  loading: boolean;
  open: boolean;
  onClose: () => void;
};

const AddBuddyModal: React.FC<AddBuddyModalProps> = ({
  formik,
  loading,
  open,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Add buddy</Typography>
        <Typography
          variant="body2"
          color="GrayText"
          sx={{
            marginBottom: 2,
          }}
        >
          Add a buddy by entering their email address. Note that they will be
          able to view all your shared content.
        </Typography>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-end",
          }}
        >
          <TextField
            id="email"
            name="email"
            label="Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            disabled={loading}
            required
            fullWidth
            sx={{
              marginBottom: 2,
            }}
          />
          <Button
            variant="contained"
            type="submit"
            sx={{
              minWidth: "150px",
              height: "40px",
            }}
            startIcon={loading && <CircularProgress size={20} />}
            disabled={loading}
          >
            Add user
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default AddBuddyModal;
