import AddGoalController from "../components/goals/add_goal/AddGoalController";
import NavigateButtonController from "../components/layout/navigate_button/NavigateButtonController";
import PageContainer from "../components/layout/PageContainer";

const GoalDetails = () => {
  return (
    <PageContainer>
      <NavigateButtonController />
      <AddGoalController />
    </PageContainer>
  );
};

export default GoalDetails;
