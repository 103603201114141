import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ReactNode } from "react";

type MultipleChoiceProps = {
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: ReactNode) => void;
  statusOptions: string[];
  placeholder: string;
  readonly?: boolean;
};

const MultipleChoice: React.FC<MultipleChoiceProps> = ({
  value,
  onChange,
  statusOptions,
  placeholder,
  readonly
}) => {
  return (
    <Select
      value={value}
      autoWidth
      sx={{
        height: "32px",
        borderRadius: "50px",
        fontSize: "0.7rem",
        padding: 1.5,
      }}
      inputProps={{
        sx: {
          paddingLeft: "0.25rem !important",
          paddingRight: "1rem !important",
        },
      }}
      id="status"
      name="status"
      placeholder="Status"
      onChange={onChange}
      displayEmpty
      disabled={readonly}
      renderValue={(selected) => {
        if (selected === "") {
          return (
            <Typography variant="body2" fontSize="0.7rem">
              {placeholder}
            </Typography>
          );
        }
        return selected;
      }}
    >
      <MenuItem disabled value="Status">
        <Typography variant="body2" fontSize="0.8rem">
          {placeholder}
        </Typography>
      </MenuItem>
      {statusOptions.map((status) => {
        if (status !== "") {
          return (
            <MenuItem key={status} value={status}>
              <Typography variant="body2" fontSize="0.8rem">
                {status}
              </Typography>
            </MenuItem>
          );
        }
        return null;
      })}
    </Select>
  );
};

export default MultipleChoice;
