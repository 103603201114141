import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Button } from "@mui/material";
import React from "react";

type NavigateButtonProps = {
  onClick: () => void;
  text: string;
};

const NavigateButton: React.FC<NavigateButtonProps> = ({ onClick, text }) => {
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-start", marginBottom: 1 }}>
      <Button onClick={onClick} size="small" startIcon={<ArrowBackIosIcon />}>
        {text}
      </Button>
    </Box>
  );
};

export default NavigateButton;
