import AddBuddyButton from "./AddBuddyButton";
import AddBuddyModalController from "../add_buddy_modal/AddBuddyModalController";
import { useState } from "react";

const AddBuddyButtonControler = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const onClick = () => {
    setOpen(true);
  };
  return (
    <>
      <AddBuddyButton onClick={onClick} />
      <AddBuddyModalController open={open} onClose={onClose} />
    </>
  );
};

export default AddBuddyButtonControler;
