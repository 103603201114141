import AddImageModalController from "../../image/add_image_modal/AddImageModalController";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import LoadingSpinner from "../../layout/LoadingSpinner";
import { UserProfileFormValues } from "@beyondrealityapp/core/user/types";
import UserProfileImageController from "../user_profile_image/UserProfileImageController";
import TextField from "@mui/material/TextField";
import React from "react";

type UserProfileFormViewProps = {
  formik: FormikHandlers &
    FormikHelpers<UserProfileFormValues> &
    FormikState<UserProfileFormValues>;
  isUserLoading: boolean;
  submitting: boolean;
  hideSubmitButton?: boolean;
  showImageModal: boolean;
  onAddUserProfileImageClick: () => void;
  onDeleteUserProfileImageClick: () => void;
  onImageModalClose: () => void;
  setImageHandler: (image: string) => void;
};

const UserProfileFormView: React.FC<UserProfileFormViewProps> = ({
  formik,
  isUserLoading,
  submitting,
  hideSubmitButton,
  showImageModal,
  onAddUserProfileImageClick,
  onDeleteUserProfileImageClick,
  onImageModalClose,
  setImageHandler,
}) => {
  const image = new Image();
  image.src = formik.values.profilePicture;

  return (
    <>
      {isUserLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
            component="form"
            onSubmit={formik.handleSubmit}
            id="user-profile-form"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                width: "100%",
              }}
            >
              <UserProfileImageController
                profilePicture={formik.values.profilePicture}
                onAddUserProfileImageClick={onAddUserProfileImageClick}
                onDeleteUserProfileImageClick={onDeleteUserProfileImageClick}
              />
              <TextField
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                fullWidth
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Box>
            {!hideSubmitButton && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress size={24} /> : "Save"}
                </Button>
              </Box>
            )}
          </Box>
          {showImageModal && (
            <AddImageModalController
              onClose={onImageModalClose}
              setImageHandler={setImageHandler}
              open={showImageModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default UserProfileFormView;
