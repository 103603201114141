import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoadingSpinner from "../../layout/LoadingSpinner";
import NoResults from "../../layout/NoResults";
import React from "react";
import { SharedUser } from "@beyondrealityapp/core/user/types";
import Typography from "@mui/material/Typography";

type SharedWithMeViewProps = {
  buddies: SharedUser[];
  isFetchingUser: boolean;
  fetchUserError: string;
  onUserCardClick: (userId: string) => void;
};

const SharedWithMeView: React.FC<SharedWithMeViewProps> = ({
  buddies,
  isFetchingUser,
  fetchUserError,
  onUserCardClick,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {fetchUserError && <Typography>{fetchUserError}</Typography>}
      {isFetchingUser && !fetchUserError && <LoadingSpinner />}
      {!buddies.length && !isFetchingUser && !fetchUserError && (
        <NoResults message="No buddies shared with you" />
      )}
      {!isFetchingUser &&
        buddies.length > 0 &&
        buddies.map((buddy) => (
          <Card
            sx={{
              width: "100%",
              cursor: "pointer",
            }}
            key={buddy.uid}
            onClick={() => onUserCardClick(buddy.uid)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingY: 1.5,
                paddingX: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {buddy.profilePicture ? (
                  <Avatar src={buddy.profilePicture} />
                ) : (
                  <Avatar>{buddy.name[0]}</Avatar>
                )}
                <Box
                  sx={{
                    marginLeft: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography>{buddy.name}</Typography>
                  <Typography variant="body2" color="GrayText">
                    {buddy.email}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <ChevronRightIcon color="action" />
              </Box>
            </Box>
          </Card>
        ))}
    </Box>
  );
};

export default SharedWithMeView;
