import AddGoalButtonController from "../add_goal_button/AddGoalButtonController";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";
import GoalsListController from "../goals_list/GoalsListController";
import ListActions from "../../layout/list_actions/ListActions";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { useNavigate } from "react-router";

type SubGoalsTabProps = {
  parentGoal: GoalClass;
  readonly?: boolean;
};

const SubGoalsTab: React.FC<SubGoalsTabProps> = ({ parentGoal, readonly }) => {
  const navigate = useNavigate();

  const generateParams = (parentGoal: GoalClass) => {
    const baseURL = `/${Page.GOALS}/-1?`;
    const params = `parent=${parentGoal.id}&themes=${parentGoal.themes}&startDate=${parentGoal.startDate?.toISOString()}&endDate=${parentGoal.endDate?.toISOString()}`;
    return baseURL + params;
  };

  const onAddSubGoalClick = () => {
    navigate(generateParams(parentGoal));
  };

  return (
    <>
      {!readonly && (
        <ListActions>
          <AddGoalButtonController
            buttonText="Add Sub-Goal"
            onClick={onAddSubGoalClick}
            variant="text"
          />
        </ListActions>
      )}
      <GoalsListController
        listId={parentGoal.id}
        userId={parentGoal.uid}
        parentGoalId={parentGoal.id}
        noResultsMessage="No sub-goals found. Click the 'Add Sub-Goal' button to create one."
        hideFilter
        compact
      />
    </>
  );
};

export default SubGoalsTab;
