import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type NoResultsProps = {
  message?: string;
};

const NoResults: React.FC<NoResultsProps> = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography variant="body1" textAlign="center">
        {message ? message : "No results found"}
      </Typography>
    </Box>
  );
};

export default NoResults;
