import BuddyOverviewController from "../components/buddies/buddy_overview/BuddyOverviewController";
import PageContainer from "../components/layout/PageContainer";

const Buddy = () => {
    return (
        <PageContainer>
            <BuddyOverviewController />
        </PageContainer>
    );
};

export default Buddy;
