import OnboardingWizardView from "./OnboardingWizardView";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";

const WizardController = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [savingUserProfile, setSavingUserProfile] = useState<boolean>(false);
  const navigate = useNavigate();
  const steps = ["Setup user profile", "Instructions"];
  const theme = useTheme();

  const onBackClick = () => {
    setActiveStep(activeStep - 1);
  };

  const onStartClick = () => {
    setActiveStep(1);
  };

  const onSaveSuccess = () => {
    navigate("/", {
      replace: true,
      state: {
        fromOnboarding: true,
      },
    });
  };

  return (
    <OnboardingWizardView
      activeStep={activeStep}
      steps={steps}
      onBackClick={onBackClick}
      onStartClick={onStartClick}
      onSaveSuccess={onSaveSuccess}
      savingUserProfile={savingUserProfile}
      setSavingUserProfile={setSavingUserProfile}
      backgroundColor={theme.palette.background.paper}
    />
  );
};

export default WizardController;
