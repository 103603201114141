import { auth } from "../../../config/firebase";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFetchThemesQuery } from "../../../api/theme";
import ThemesListView from "./ThemesListView";

type ThemeListControllerProps = {
  userId?: string;
  readonly?: boolean;
};

const ThemesListController: React.FC<ThemeListControllerProps> = ({
  userId,
  readonly,
}) => {
  const dispatch = useDispatch();
  const {
    data: themes = [],
    isLoading,
    isError,
    error,
  } = useFetchThemesQuery(userId ?? auth.currentUser?.uid ?? "");

  useEffect(() => {
    if (isError) {
      dispatch(
        showSnackbar({
          message: error instanceof Error ? error.message : "An error occurred",
          severity: Severity.ERROR,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <ThemesListView
      themes={themes}
      loading={isLoading}
      isError={isError}
      error={error}
      readonly={readonly}
    />
  );
};

export default ThemesListController;
