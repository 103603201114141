import { auth } from "../../../config/firebase";
import BuddyOverviewView from "./BuddyOverviewView";
import LoadingSpinner from "../../layout/LoadingSpinner";
import { useFetchUserQuery } from "../../../api/user";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { useState } from "react";
import { Page } from "@beyondrealityapp/core/shared/constants";

const BuddyOverviewController = () => {
  const { buddyId } = useParams<{ buddyId: string }>();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    data: user,
    isLoading: isFetchingUser,
  } = useFetchUserQuery(auth.currentUser?.uid ?? "", {
    skip: !auth.currentUser?.uid,
  });

  const buddyName = user?.sharedWithMe.find((buddy) => buddy.uid === buddyId);

  const onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const onBackButtonClick = () => {
    navigate(`/${Page.ACCOUNTABILITY_BUDDIES}?tab=shared-with-me`);
  };

  return (
    <>
      {buddyId && !isFetchingUser && user ? (
        <BuddyOverviewView
          buddyId={buddyId}
          buddyName={buddyName?.name ?? ""}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
          onBackButtonClick={onBackButtonClick}
        />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default BuddyOverviewController;
