import SharedTabsController from "../components/buddies/share_tabs/ShareTabsController";
import PageContainer from "../components/layout/PageContainer";

const AccountabilityBuddies = () => {
    return (
        <PageContainer>
            <SharedTabsController />
        </PageContainer>
    );
};

export default AccountabilityBuddies;
