import AddImageButtonController from "../../image/add_image_button/AddImageButtonController";
import AddImageModalController from "../../image/add_image_modal/AddImageModalController";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardImageHeaderController from "../../image/card_image_header/CardImageHeaderController";
import Collapse from "@mui/material/Collapse";
import DefaultModal from "../../layout/DefaultModal";
import { FormikProps } from "formik";
import GoalsListController from "../../goals/goals_list/GoalsListController";
import Grid from "@mui/material/Grid";
import SubmitDeleteActions from "../../layout/SubmitDeleteActions";
import Textfield from "@mui/material/TextField";
import { ThemeType } from "@beyondrealityapp/core/theme/types";
import Typography from "@mui/material/Typography";

type ThemeModalViewProps = {
  isOpen: boolean;
  formik: FormikProps<ThemeType>;
  onClose: () => void;
  setImageModalOpen: (open: boolean) => void;
  imageDeleteHandler: () => void;
  error: string;
  cancelHandler: () => void;
  deleteHandler: () => void;
  allowDelete?: boolean;
  submitting: boolean;
  deleting: boolean;
  imageModalOpen: boolean;
  setImage: (image: string) => void;
  offSetHandler: (offset: number) => void;
};

const ThemeModalView: React.FC<ThemeModalViewProps> = ({
  isOpen,
  onClose,
  formik,
  setImageModalOpen,
  imageDeleteHandler,
  error,
  cancelHandler,
  deleteHandler,
  allowDelete,
  submitting,
  deleting,
  imageModalOpen,
  setImage,
  offSetHandler,
}) => {
  return (
    <>
      <DefaultModal isOpen={isOpen} onClose={onClose}>
        {formik.values.image ? (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <CardImageHeaderController
              imageUrl={formik.values.image}
              verticalOffset={formik.values.imageVerticalOffset ?? 50}
              imageDeleteHandler={imageDeleteHandler}
              offSetHandler={offSetHandler}
            />
          </Box>
        ) : null}
        <Box
          onSubmit={formik.handleSubmit}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent
            sx={{ overflowY: "auto", maxHeight: "calc(100vh - 200px)" }}
          >
            {!formik.values.id && (
              <>
                <Typography variant="h5" gutterBottom>
                  Add Theme
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Enter a name for your theme and (optionally) add an image and
                  description
                </Typography>
              </>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <Textfield
                    id="name"
                    name="name"
                    label="Name"
                    variant="standard"
                    sx={{ width: "100%" }}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <AddImageButtonController
                    handleClick={() => setImageModalOpen(true)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Textfield
                  id="description"
                  name="description"
                  label="Description"
                  variant="standard"
                  multiline
                  sx={{ width: "100%" }}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
            </Grid>
            {isOpen && formik.values.id && (
              <>
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Goals
                </Typography>
                <GoalsListController
                  compact
                  hideFilter
                  defaultFilter={{
                    status: [],
                    period: [],
                    theme: [formik.values.name],
                    showChildren: false,
                  }}
                  listId={formik.values.id}
                  noResultsMessage="No goals found for this theme."
                />
              </>
            )}
            <Collapse in={Boolean(error)}>
              <Alert severity="error" sx={{ marginTop: 2 }}>
                {error}
              </Alert>
            </Collapse>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Box sx={{ marginX: 2, marginBottom: 1, width: "100%" }}>
              <SubmitDeleteActions
                cancelHandler={cancelHandler}
                deleteHandler={deleteHandler}
                allowDelete={allowDelete}
                submitting={submitting}
                deleting={deleting}
              />
            </Box>
          </CardActions>
        </Box>
      </DefaultModal>
      {/* TODO: Only render the AddImageModalController if the imagePickerOpen is true */}
      <AddImageModalController
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        setImageHandler={setImage}
      />
    </>
  );
};

export default ThemeModalView;
