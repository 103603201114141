import Box from "@mui/material/Box";
import ListActions from "../../layout/list_actions/ListActions";
import SharedWithMeController from "../shared_with_me/SharedWithMeController";
import SharedWithOthersController from "../shared_with_others/SharedWithOthersController";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AddBuddyButtonControler from "../add_buddy_button/AddBuddyButtonControler";

type BuddiesTabViewProps = {
  onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  selectedTab: number;
};

const ShareTabsView = ({ onTabChange, selectedTab }: BuddiesTabViewProps) => {
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Shared with me" />
        <Tab label="Shared with others" />
      </Tabs>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          width: "100%",
        }}
      >
        {selectedTab === 0 && <SharedWithMeController />}
        {selectedTab === 1 && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <ListActions>
              <AddBuddyButtonControler />
            </ListActions>
            <SharedWithOthersController />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ShareTabsView;
