import AddImageModalView from "./AddImageModalView";
import { auth, storage } from "../../../config/firebase";
import { CoverImage } from "@beyondrealityapp/core/shared/types";
import { functions } from "../../../config/firebase";
import { httpsCallable } from "firebase/functions";
import { useImageUpload } from "../../../hooks/useImageUpload";
import { useRef, useState } from "react";

type AddImageModalControllerProps = {
  open: boolean;
  onClose: () => void;
  setImageHandler: (image_url: string) => void;
};

const AddImageModalController: React.FC<AddImageModalControllerProps> = ({
  open,
  onClose,
  setImageHandler,
}) => {
  const getImages = httpsCallable(functions, "coverImage");
  const [images, setImages] = useState<CoverImage[]>([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { isCompressing, isUploading, uploadImage } = useImageUpload(
    storage,
    auth
  );

  const searchHandler = () => {
    setLoading(true);
    setError("");
    getImages({ action: "search", query: query })
      .then((response: any) => {
        setImages(response["data"]);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFileChangeHandler = (file: File) => {
    uploadImage(file)
      .then((url: string) => {
        setImageHandler(url);
        onClose();
      })
      .catch((error: Error) => {
        setError(error.message);
      });
  };

  const onEnterHandler = (e: any) => {
    if (e.key === "Enter") {
      searchHandler();
      e.target.blur();
    }
  };

  const onImageClickHandler = (image: CoverImage) => {
    setImageHandler(image["image_url"]);
    getImages({ action: "select", select_url: image["select_url"] }).catch(
      (error) => {
        setError(error.message);
      }
    );
    onClose();
  };

  const onTextChangeHandler = (e: any) => {
    setQuery(e.target.value);
  };

  const onClearClickerHandler = () => {
    setQuery("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setImages([]);
  };

  return (
    <AddImageModalView
      open={open}
      query={query}
      images={images}
      loading={loading}
      error={error}
      isCompressing={isCompressing}
      isUploading={isUploading}
      inputRef={inputRef}
      onClose={onClose}
      searchHandler={searchHandler}
      onTextChangeHandler={onTextChangeHandler}
      onFileChangeHandler={onFileChangeHandler}
      onEnterHandler={onEnterHandler}
      onImageClickHandler={onImageClickHandler}
      onClearClickHandler={onClearClickerHandler}
    />
  );
};

export default AddImageModalController;
