import { Box } from "@mui/material";

type ListActionsProps = {
  children: React.ReactNode;
};

const ListActions: React.FC<ListActionsProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
        gap: 2,
      }}
    >
      {children}
    </Box>
  );
};

export default ListActions;
