import AddGoalButton from "./AddGoalButton";
import { Page } from "@beyondrealityapp/core/shared/constants";
import React from "react";
import { useNavigate } from "react-router";

type AddGoalButtonControllerProps = {
  onClick?: () => void;
  variant?: "contained" | "outlined" | "text";
  buttonText?: string;
};

const AddGoalButtonController: React.FC<AddGoalButtonControllerProps> = ({
  onClick,
  variant,
  buttonText,
}) => {
  const navigate = useNavigate();
  if (!onClick) {
    onClick = () => {
      navigate(`/${Page.GOALS}/-1`);
    };
  }
  return (
    <AddGoalButton
      onClick={onClick}
      variant={variant}
      buttonText={buttonText}
    />
  );
};

export default AddGoalButtonController;
