import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

type UserProfileImageViewProps = {
  profilePicture: string;
  onAddUserProfileImageClick: () => void;
  onDeleteUserProfileImageClick: () => void;
};

const UserProfileImageView: React.FC<UserProfileImageViewProps> = ({
  profilePicture,
  onAddUserProfileImageClick,
  onDeleteUserProfileImageClick,
}) => {
  return (
    <>
      {profilePicture ? (
        <IconButton
          onClick={onDeleteUserProfileImageClick}
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            src={profilePicture}
            alt="Profile"
            style={{
              width: "120px",
              height: "120px",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0,
              "&:hover": {
                opacity: 1,
                transition: "opacity 0.1s ease-in-out",
              },
            }}
          >
            <DeleteIcon
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                height: "24px",
                width: "24px",
              }}
            />
          </Box>
        </IconButton>
      ) : (
        <IconButton
          onClick={onAddUserProfileImageClick}
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            overflow: "hidden",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          }}
        >
          <PhotoCameraIcon />
        </IconButton>
      )}
    </>
  );
};

export default UserProfileImageView;
