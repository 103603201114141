import { auth } from "../../../config/firebase";
import { Page } from "@beyondrealityapp/core/shared/constants";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import SharedWithMeView from "./SharedWithMeView";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useFetchUserQuery } from "../../../api/user";
import { useNavigate } from "react-router";

const SharedWithMeController = () => {
  const {
    data: user,
    isError: isFetchUserError,
    error: fetchUserError,
    isLoading: isFetchingUser,
  } = useFetchUserQuery(auth.currentUser?.uid ?? "", {
    skip: !auth.currentUser?.uid,
  });
  const buddies = user?.sharedWithMe ?? [];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onUserCardClick = (userId: string) => {
    auth.currentUser
      ?.getIdToken(true)
      .then(() => {
        navigate(`/${Page.ACCOUNTABILITY_BUDDIES}/${userId}`);
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            severity: Severity.ERROR,
            message:
              error instanceof Error
                ? error.message
                : "An error occurred while refreshing your user token.",
          })
        );
      });
  };

  return (
    <SharedWithMeView
      buddies={buddies}
      isFetchingUser={isFetchingUser}
      fetchUserError={isFetchUserError ? (fetchUserError as string) : ""}
      onUserCardClick={onUserCardClick}
    />
  );
};

export default SharedWithMeController;
