import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";

type WizardViewStepZeroProps = {
  onStartClick: () => void;
};

const WizardViewStepZero: React.FC<WizardViewStepZeroProps> = ({
  onStartClick,
}) => {
  const onboardingImage = "assets/wizard_step_zero.svg";
  const image = new Image();
  image.src = onboardingImage;
  image.width = 300;
  image.height = 300;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Typography variant="h6" color="primary">
          Beyond
        </Typography>
        <Typography variant="h6">Reality</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 0,
        }}
      >
        <img
          src={onboardingImage}
          alt="Onboarding"
          width={image.width}
          height={image.height}
        />
      </Box>
      <Box>
        <Typography variant="body1" align="center">
          Before we get started, let's set up your user profile.
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={onStartClick}
        sx={{ marginTop: "20px", marginBottom: "20px" }}
      >
        Setup Profile
      </Button>
    </Box>
  );
};

export default WizardViewStepZero;
