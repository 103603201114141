import { auth } from "../../config/firebase";
import NavBarView from "./NavBarView";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../features/snackbar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useFetchUserQuery } from "../../api/user";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

type MenuProps = {
  leftOpen: boolean;
  rightOpen: boolean;
};

const NavBarController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [menu, setMenu] = useState<MenuProps>({
    leftOpen: false,
    rightOpen: false,
  });

  const onLogoClick = () => {
    navigate("/");
  };

  const onNavigationMenuClick = () => {
    setMenu((prev) => ({
      ...prev,
      leftOpen: true,
    }));
  };

  const onUserProfileClick = () => {
    setMenu((prev) => ({
      ...prev,
      rightOpen: true,
    }));
  };

  const onBackdropClick = () => {
    setMenu({
      leftOpen: false,
      rightOpen: false,
    });
  };

  const {
    data: user,
    error: userError,
    isError: isErrorUser,
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useFetchUserQuery(auth.currentUser?.uid ?? "");

  useEffect(() => {
    if (isErrorUser) {
      dispatch(
        showSnackbar({
          severity: Severity.ERROR,
          message:
            userError instanceof Error
              ? userError.message
              : "An error occurred while loading your user",
        })
      );
    }
    if (user && user.name === "" && !location.state?.fromOnboarding) {
      navigate("/onboarding");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorUser, user]);

  return (
    <NavBarView
      navgationMenuOpen={menu.leftOpen}
      userProfileOpen={menu.rightOpen}
      onNavigationMenuClick={onNavigationMenuClick}
      onUserProfileClick={onUserProfileClick}
      onBackdropClick={onBackdropClick}
      onLogoClick={onLogoClick}
      user={user}
      refetchUser={refetchUser}
      isUserLoading={isLoadingUser}
      isUserError={isErrorUser}
    />
  );
};

export default NavBarController;
