import Box from "@mui/material/Box";
import { forwardRef } from "react";
import UserProfileFormController from "../../user/user_profile_form/UserProfileFormController";
import Typography from "@mui/material/Typography";

type WizardViewStepOneProps = {
  onSaveSuccess: () => void;
  setSavingUserProfile: (saving: boolean) => void;
};

const WizardViewStepOne = forwardRef<
  { submit: () => void },
  WizardViewStepOneProps
>(({ onSaveSuccess, setSavingUserProfile }, ref) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        marginTop: 2,
        width: {
          xs: "100%",
          sm: "80%",
          md: "60%",
        },
      }}
    >
      <Typography variant="h5">Setup user profile</Typography>
      <UserProfileFormController
        onSaveSuccess={onSaveSuccess}
        setSavingUserProfile={setSavingUserProfile}
        ref={ref}
        hideSubmitButton={true}
      />
    </Box>
  );
});

export default WizardViewStepOne;
