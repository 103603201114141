import AddGoalButtonController from "../components/goals/add_goal_button/AddGoalButtonController";
import GoalsListController from "../components/goals/goals_list/GoalsListController";
import ListActions from "../components/layout/list_actions/ListActions";
import PageContainer from "../components/layout/PageContainer";

const Goals = () => {
  return (
    <PageContainer>
      <ListActions>
        <AddGoalButtonController />
      </ListActions>
      <GoalsListController listId="goalsPage" />
    </PageContainer>
  );
};

export default Goals;
