import AddBuddyModal from "./AddBuddyModal";
import { useFormik } from "formik";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { string } from "yup";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { useAddBuddyMutation } from "../../../api/user";

type AddBuddyModalControllerProps = {
  open: boolean;
  onClose: () => void;
};

const AddBuddyModalController: React.FC<AddBuddyModalControllerProps> = ({
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [addBuddy] = useAddBuddyMutation();

  const onSubmit = () => {
    setLoading(true);
    addBuddy(formik.values.email)
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Buddy added successfully",
            severity: Severity.SUCCESS,
          })
        );
        formik.resetForm();
        onClose();
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error.message,
            severity: Severity.ERROR,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialValues = {
    email: "",
  };

  const AddBuddySchema = string().email().required();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: AddBuddySchema,
  });

  return (
    <AddBuddyModal
      formik={formik}
      loading={loading}
      open={open}
      onClose={onClose}
    />
  );
};

export default AddBuddyModalController;
