import { auth, db } from "../config/firebase";
import { Auth } from "firebase/auth";
import {
  and,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  orderBy,
  runTransaction,
  where,
} from "firebase/firestore";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { ErrorMessage } from "@beyondrealityapp/core/shared/constants";
import { Firestore } from "firebase/firestore";
import { Model } from "@beyondrealityapp/core/shared/constants";
import { Operator } from "@beyondrealityapp/core/shared/constants";
import { ThemeClass } from "@beyondrealityapp/core/theme/classes";
import { ThemeField } from "@beyondrealityapp/core/theme/constants";
import {
  ThemeFormType,
  ThemeFirestoreType,
  ThemeType,
} from "@beyondrealityapp/core/theme/types";
import { UserField } from "@beyondrealityapp/core/user/constants";

interface deleteThemeArgs {
  themeId: string;
  themeName: string;
}

export const themesApi = createApi({
  reducerPath: "themesApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Theme", "UserProfileTheme"],
  endpoints: (builder) => ({
    fetchThemes: builder.query({
      queryFn: async (userId: string) => {
        try {
          const themes = await _fetchThemes(userId);
          return { data: themes };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["Theme"],
    }),
    fetchThemesFromUserProfile: builder.query({
      queryFn: async () => {
        try {
          const themes = await _fetchThemesFromUserProfile();
          return { data: themes };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["UserProfileTheme"],
    }),
    fetchTheme: builder.query({
      queryFn: async (themeId: string) => {
        try {
          const theme = await _fetchTheme(themeId);
          return { data: theme };
        } catch (error) {
          return { error: error as Error };
        }
      },
      providesTags: ["Theme"],
    }),
    createUpdateTheme: builder.mutation({
      queryFn: async (themeForm: ThemeFormType) => {
        try {
          await _createUpdateTheme(themeForm);
          return { data: undefined };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Theme"],
    }),
    deleteTheme: builder.mutation({
      queryFn: async ({ themeId, themeName }: deleteThemeArgs) => {
        try {
          await _deleteTheme(themeId, themeName, auth, db);
          return { data: undefined };
        } catch (error) {
          return { error: error as Error };
        }
      },
      invalidatesTags: ["Theme"],
    }),
  }),
});

export const {
  useFetchThemesQuery,
  useFetchThemesFromUserProfileQuery,
  useFetchThemeQuery,
  useCreateUpdateThemeMutation,
  useDeleteThemeMutation,
} = themesApi;

const _fetchThemes = async (userId?: string) => {
  let uid = "";
  if (userId) {
    uid = userId;
  } 
  if (!userId && auth.currentUser) {
    uid = auth.currentUser.uid;
  }

  return new Promise<ThemeClass[]>((resolve, reject) => {
    const q = query(
      collection(db, Model.THEMES),
      where(ThemeField.UID, Operator.EQUAL_TO, uid),
      orderBy(ThemeField.NAME)
    );
    getDocs(q)
      .then((querySnapshot) => {
        const tempThemes = [] as ThemeClass[];
        querySnapshot.forEach((doc) => {
          const theme = ThemeClass.fromFirestore(
            doc.data() as ThemeFirestoreType
          );
          tempThemes.push(theme);
        });
        resolve(tempThemes);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchThemesFromUserProfile = () => {
  return new Promise<string[]>((resolve, reject) => {
    const q = query(
      collection(db, Model.USERS),
      where(UserField.UID, Operator.EQUAL_TO, auth.currentUser?.uid)
    );
    getDocs(q)
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().themes.length > 0) {
            resolve(doc.data().themes.sort());
          } else {
            resolve([]);
          }
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _fetchTheme = (id: string) => {
  return new Promise<ThemeType>((resolve, reject) => {
    const themeRef = doc(db, Model.THEMES, id);
    getDoc(themeRef)
      .then((doc) => {
        if (doc.exists()) {
          const theme = doc.data() as ThemeType;
          resolve(theme);
        } else {
          reject(new Error(ErrorMessage.NO_ID));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _createUpdateTheme = (theme: ThemeFormType) => {
  return new Promise<void>((resolve, reject) => {
    if (theme.id !== "") {
      _updateTheme(theme)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      _createTheme(theme)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

export const _deleteTheme = (
  themeId: string,
  themeName: string,
  auth: Auth,
  db: Firestore
) => {
  if (!auth.currentUser?.uid) {
    throw new Error(ErrorMessage.NOT_AUTHORIZED);
  }
  const uid = auth.currentUser.uid;

  return new Promise<void>((resolve, reject) => {
    const goalsRef = collection(db, Model.GOALS);
    const goalsQuery = query(
      goalsRef,
      and(
        where(ThemeField.UID, Operator.EQUAL_TO, uid),
        where(Model.THEMES, Operator.ARRAY_CONTAINS, themeName)
      )
    );
    runTransaction(db, async (transaction) => {
      await getDocs(goalsQuery).then((querySnapshot) => {
        querySnapshot.forEach((goalDoc) => {
          transaction.update(doc(db, Model.GOALS, goalDoc.id), {
            themes: arrayRemove(themeName),
          });
        });
      });
      transaction.update(doc(db, Model.USERS, uid), {
        themes: arrayRemove(themeName),
      });
      transaction.delete(doc(db, Model.THEMES, themeId));
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const _createTheme = (themeForm: ThemeFormType) => {
  return new Promise<void>((resolve, reject) => {
    const theme = ThemeClass.fromForm(themeForm);
    theme.init(auth);

    const goalsQuery = query(
      collection(db, Model.GOALS),
      and(
        where(ThemeField.UID, Operator.EQUAL_TO, theme.uid),
        where(Model.THEMES, Operator.ARRAY_CONTAINS, theme.name)
      )
    );

    getDocs(goalsQuery).then((querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.forEach((goalDoc) => {
          theme.goals.push({
            id: goalDoc.id,
            content: goalDoc.data().content,
          });
        });
      }
      runTransaction(db, async (transaction) => {
        transaction.set(doc(db, Model.THEMES, theme.id), theme.toFirestore());
        transaction.update(doc(db, Model.USERS, theme.uid), {
          themes: arrayUnion(theme.name),
        });
      })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

const _updateTheme = (themeForm: ThemeFormType) => {
  return new Promise<void>((resolve, reject) => {
    const theme = ThemeClass.fromForm(themeForm);
    theme.setUpdatedTimestamp();
    getDoc(doc(db, Model.THEMES, theme.id))
      .then((existingTheme) => {
        if (existingTheme.exists()) {
          const existingThemeName = existingTheme.data().name;

          runTransaction(db, async (transaction) => {
            if (existingThemeName !== theme.name) {
              const goalsQuery = query(
                collection(db, Model.GOALS),
                and(
                  where(ThemeField.UID, Operator.EQUAL_TO, theme.uid),
                  where(
                    Model.THEMES,
                    Operator.ARRAY_CONTAINS,
                    existingThemeName
                  )
                )
              );

              const querySnapshot = await getDocs(goalsQuery);
              querySnapshot.forEach((goalDoc) => {
                transaction.update(doc(db, Model.GOALS, goalDoc.id), {
                  themes: arrayUnion(theme.name),
                });
                transaction.update(doc(db, Model.GOALS, goalDoc.id), {
                  themes: arrayRemove(existingThemeName),
                });
              });

              transaction.update(doc(db, Model.USERS, theme.uid), {
                themes: arrayUnion(themeForm.name),
              });
              transaction.update(doc(db, Model.USERS, theme.uid), {
                themes: arrayRemove(existingThemeName),
              });
            }
            transaction.update(doc(db, Model.THEMES, themeForm.id), themeForm);
          })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(ErrorMessage.NO_ID);
        }
      })
      .catch((error) => reject(error));
  });
};
