import PageContainer from "../components/layout/PageContainer";
import UserProfileContainer from "../components/user/user_profile_container/UserProfileContainer";
import UserProfileFormController from "../components/user/user_profile_form/UserProfileFormController";

const UserProfile = () => {
  return (
    <PageContainer>
      <UserProfileContainer>
        <UserProfileFormController />
      </UserProfileContainer>
    </PageContainer>
  );
};

export default UserProfile;