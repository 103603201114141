import UserProfileImageView from "./UserProfileImageView";

type UserProfileImageControllerProps = {
  profilePicture: string;
  onAddUserProfileImageClick: () => void;
  onDeleteUserProfileImageClick: () => void;
};

const UserProfileImageController: React.FC<UserProfileImageControllerProps> = ({
  profilePicture,
  onAddUserProfileImageClick,
  onDeleteUserProfileImageClick,
}) => {
  return (
    <UserProfileImageView
      profilePicture={profilePicture}
      onAddUserProfileImageClick={onAddUserProfileImageClick}
      onDeleteUserProfileImageClick={onDeleteUserProfileImageClick}
    />
  );
};

export default UserProfileImageController;
