import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";

type MenuHeaderProps = {
  onClose: () => void;
};

const MenuHeader = ({ onClose }: MenuHeaderProps) => {
  const theme = useTheme();
  return (
    <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
        <IconButton onClick={onClose} size="large">
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
    </Box>
  );
};

export default MenuHeader;
