import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import GoalsListController from "../../goals/goals_list/GoalsListController";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ThemesListController from "../../themes/themes_list/ThemesListController";
import Typography from "@mui/material/Typography";

type BuddyTabsViewProps = {
  buddyId: string;
  buddyName: string;
  onTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  selectedTab: number;
  onBackButtonClick: () => void;
};

const BuddyOverviewView: React.FC<BuddyTabsViewProps> = ({
  buddyId,
  buddyName,
  onTabChange,
  selectedTab,
  onBackButtonClick,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button onClick={onBackButtonClick} startIcon={<ArrowBackIosIcon />}>
          Overview
        </Button>
        <Typography variant="h5" gutterBottom>
          {buddyName}
        </Typography>
        <Box
          sx={{
            width: "96px",
          }}
        ></Box>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={onTabChange}
        aria-label="buddy tabs"
        centered
      >
        <Tab label="Goals" />
        <Tab label="Themes" />
      </Tabs>
      <Box
        role="tabpanel"
        hidden={selectedTab !== 0}
        id={`buddy-tabpanel-${buddyId}-0`}
        aria-labelledby={`buddy-tab-${buddyId}-0`}
        width="100%"
      >
        <GoalsListController
          listId="buddy"
          userId={buddyId}
          defaultFilter={{
            status: ["In progress", "Not started", "Overdue"],
            period: [],
            theme: [],
            showChildren: false,
          }}
        />
      </Box>
      <Box
        role="tabpanel"
        hidden={selectedTab !== 1}
        id={`buddy-tabpanel-${buddyId}-1`}
        aria-labelledby={`buddy-tab-${buddyId}-1`}
        width="100%"
        marginTop={2}
      >
        <ThemesListController userId={buddyId} readonly={true} />
      </Box>
    </>
  );
};

export default BuddyOverviewView;
