import { auth } from "../../../config/firebase";
import Box from "@mui/material/Box";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import SharedWithOthersView from "./SharedWithOthersView";
import { showSnackbar } from "../../../features/snackbar";
import { useDispatch } from "react-redux";
import { useFetchUserQuery, useRemoveBuddyMutation } from "../../../api/user";
import { useState } from "react";

const SharedWithOthersController = () => {
  const [isRemovingBuddy, setIsRemovingBuddy] = useState<boolean>(false);
  const [removeBuddy] = useRemoveBuddyMutation();
  const dispatch = useDispatch();
  const {
    data: user,
    isError: isFetchUserError,
    error: fetchUserError,
    isLoading: isFetchingUser,
  } = useFetchUserQuery(auth.currentUser?.uid ?? "", {
    skip: !auth.currentUser?.uid,
  });
  const buddies = user?.sharedWithOthers ?? [];

  const onRemoveBuddyClick = async (buddyEmail: string) => {
    setIsRemovingBuddy(true);
    removeBuddy(buddyEmail)
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            message: "Buddy removed successfully",
            severity: Severity.SUCCESS,
          })
        );
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: error.message,
            severity: Severity.ERROR,
          })
        );
      })
      .finally(() => {
        setIsRemovingBuddy(false);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SharedWithOthersView
        buddies={buddies}
        isFetchingUser={isFetchingUser}
        fetchUserError={isFetchUserError ? (fetchUserError as string) : ""}
        onRemoveBuddyClick={onRemoveBuddyClick}
        isRemovingBuddy={isRemovingBuddy}
      />
    </Box>
  );
};

export default SharedWithOthersController;
