import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MenuController from "./menu/MenuController";
import MenuIcon from "@mui/icons-material/Menu";
import { Outlet } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { User } from "@beyondrealityapp/core/user/classes";
import { useRef } from "react";
import UserProfileMenuController from "./user_profile_menu/UserProfileMenuController";
import WarningIcon from "@mui/icons-material/Warning";

type NavbarViewProps = {
  navgationMenuOpen: boolean;
  userProfileOpen: boolean;
  onNavigationMenuClick: () => void;
  onUserProfileClick: () => void;
  onBackdropClick: () => void;
  onLogoClick: () => void;
  user: User | undefined;
  refetchUser: () => void;
  isUserLoading: boolean;
  isUserError: boolean;
};

const NavBarView: React.FC<NavbarViewProps> = ({
  navgationMenuOpen,
  userProfileOpen,
  onNavigationMenuClick,
  onUserProfileClick,
  onBackdropClick,
  onLogoClick,
  user,
  refetchUser,
  isUserLoading,
  isUserError,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  return (
    <>
      <AppBar position="sticky" color="inherit">
        <MenuController
          open={navgationMenuOpen}
          onClose={onBackdropClick}
          onOpen={onNavigationMenuClick}
        />
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={onNavigationMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                }}
                onClick={onLogoClick}
              >
                <Typography variant="h6" color="primary">
                  Beyond
                </Typography>
                <Typography variant="h6">Reality</Typography>
              </Box>
            </Box>
          </Box>
          {isUserLoading && !isUserError && (
            <CircularProgress color="primary" size={24} />
          )}
          {isUserError && (
            <IconButton
              edge="end"
              color="error"
              aria-label="warning"
              onClick={refetchUser}
            >
              <WarningIcon />
            </IconButton>
          )}
          {user && !isUserLoading && !isUserError && (
            <>
              <IconButton
                color="primary"
                edge="end"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                id="user-profile-button"
                ref={buttonRef}
                onClick={onUserProfileClick}
                disabled={isUserLoading || isUserError}
              >
                <Avatar
                  alt="User Avatar"
                  src={user?.profilePicture}
                  sx={{ width: 32, height: 32 }}
                >
                  {user?.name.charAt(0) ?? "U"}
                </Avatar>
              </IconButton>
              <UserProfileMenuController
                open={userProfileOpen}
                onClose={onBackdropClick}
                anchorEl={buttonRef.current}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Outlet />
    </>
  );
};

export default NavBarView;
