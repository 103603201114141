import ShareTabsView from "./ShareTabsView";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const SharedTabsController = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [searchParams] = useSearchParams();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab === "shared-with-me") {
      setSelectedTab(1);
      return;
    }
    if (tab === "shared-with-others") {
      setSelectedTab(0);
      return;
    }
  }, [searchParams]);

  return (
    <ShareTabsView onTabChange={handleTabChange} selectedTab={selectedTab} />
  );
};

export default SharedTabsController;
