import { DateStyle, Locale } from "@beyondrealityapp/core/shared/constants";
import GoalCardView from "./GoalCardView";
import { GoalType } from "@beyondrealityapp/core/goal/types";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useLoadImage } from "../../../hooks/useLoadImage";
import { formatGoalProgress } from "../../../utils/goal/formatGoalProgress";
import { GoalClass } from "@beyondrealityapp/core/goal/classes";

type GoalCardControllerProps = {
  goal: GoalType | GoalClass;
  highlight?: boolean;
  compact?: boolean;
};

const GoalCardController: React.FC<GoalCardControllerProps> = ({
  goal,
  compact,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  const { image } = useLoadImage(goal.image, 200);

  const id = goal.id ?? "";
  const content = goal.content ?? "";
  const progress = formatGoalProgress(goal.target, goal.current, goal.kpi);
  const description = goal.description ?? "";
  const dates =
    goal.startDate instanceof Date && goal.endDate instanceof Date
      ? `${goal.startDate.toLocaleDateString(Locale.EN, {
          dateStyle: DateStyle.FULL,
        })} - ${goal.endDate.toLocaleDateString(Locale.EN, {
          dateStyle: DateStyle.FULL,
        })}`
      : "";

  const children = goal.children ?? {};
  const themes = goal.themes && goal.themes.length !== 0 ? goal.themes : [];
  const updatedAt =
    goal.metaData && goal.metaData.updatedAt instanceof Date
      ? goal.metaData.updatedAt.toLocaleDateString(Locale.EN)
      : "";

  let highlightColor = "";
  if (goal.status === "Completed") {
    highlightColor = theme.palette.success.main;
  }
  if (goal.status === "In progress") {
    highlightColor = theme.palette.info.main;
  }
  if (goal.status === "Overdue") {
    highlightColor = theme.palette.error.main;
  }
  if (goal.status === "Not started") {
    highlightColor = theme.palette.secondary.main;
  }

  const onClickHandler = () => {
    navigate(`/goals/${goal.id}`);
  };

  const onExpandClickHandler = () => {
    setExpanded((prevState) => !prevState);
  };

  const onSubGoalClickHandler = (event: React.MouseEvent, id: string) => {
    event.stopPropagation();
    navigate(`/goals/${id}`);
  };

  return (
    <GoalCardView
      onClickHandler={onClickHandler}
      onExpandClickHandler={onExpandClickHandler}
      onSubgoalClickHandler={onSubGoalClickHandler}
      expanded={expanded}
      highlightColor={highlightColor}
      image={image}
      content={content}
      description={description}
      progress={progress}
      dates={dates}
      themes={themes}
      children={children}
      parent={id}
      updatedAt={updatedAt}
      compact={compact}
    />
  );
};

export default GoalCardController;
