import AccountabilityBuddies from "./pages/AccountabilityBuddies";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddPersonalityTestModal from "./components/personality_tests/AddPersonalityTestModal";
import AdminPanel from "./pages/AdminPanel";
import AwaitingVerification from "./pages/AwaitingVerification";
import Box from "@mui/material/Box";
import Buddy from "./pages/Buddy";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CoreValues from "./pages/CoreValues";
import CssBaseline from "@mui/material/CssBaseline";
import GenericSnackbarController from "./components/generic/generic_snackbar/GenericSnackbarController";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import Goals from "./pages/Goals";
import Home from "./pages/Home";
import JournalEntry from "./pages/JournalEntry";
import LoadingSpinner from "./components/layout/LoadingSpinner";
import { LocalizationProvider } from "@mui/x-date-pickers";
import NavBarController from "./components/nav_bar/NavBarController";
import { useEffect, useState } from "react";
import Onboarding from "./pages/Onboarding";
import OnlineProvider from "./providers/OnlineProvider";
import { Page } from "@beyondrealityapp/core/shared/constants";
import PersonalityTests from "./pages/PersonalityTests";
import { Provider } from "react-redux";
import ProtectedRoute from "./components/authentication/ProtectedRoute";
import RedirectSignInUpRoute from "./components/authentication/RedirectSignInRoute";
import Journal from "./pages/Journal";
import Reviews from "./pages/Reviews";
import ReviewDetails from "./pages/ReviewDetails";
import { Routes, Route } from "react-router-dom";
import Settings from "./pages/Settings";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { store } from "./config/store";
import Themes from "./pages/Themes";
import { useLocation } from "react-router-dom";
import UserProfile from "./pages/UserProfile";
import "@mdxeditor/editor/style.css";
import GoalDetails from "./pages/GoalDetails";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#20A76F",
      contrastText: "#fff",
    },
    secondary: {
      main: "#A72058",
    },
    success: {
      main: "#73BB2A",
      contrastText: "#fff",
    },
    error: {
      main: "#C91D25",
    },
    warning: {
      main: "#E8A530",
    },
    background: {
      default: "#F4F6F4",
      paper: "#fdfdfc",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,
  },
});

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, () => {
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <LoadingSpinner />;
      </Box>
    );
  }
  return (
    <Provider store={store}>
      <OnlineProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline>
              <Routes location={previousLocation || location}>
                <Route
                  path={Page.AWAITING_VERIFICATION}
                  element={<AwaitingVerification />}
                />
                <Route
                  element={<ProtectedRoute allowedRoles={["admin", "user"]} />}
                >
                  <Route path={Page.ONBOARDING} element={<Onboarding />} />
                  <Route element={<NavBarController />}>
                    <Route index element={<Home />} />
                    <Route path={Page.CORE_VALUES} element={<CoreValues />} />
                    <Route path={Page.THEMES} element={<Themes />} />
                    <Route path={Page.GOALS} element={<Goals />} />
                    <Route path={Page.GOAL_DETAILS} element={<GoalDetails />} />
                    <Route
                      path={Page.PERSONALITY_TEST}
                      element={<PersonalityTests />}
                    />
                    <Route path={Page.JOURNAL} element={<Journal />} />
                    <Route
                      path={Page.JOURNAL_ENTRY}
                      element={<JournalEntry />}
                    />
                    <Route path={Page.REVIEWS} element={<Reviews />} />
                    <Route
                      path={Page.REVIEW_DETAILS}
                      element={<ReviewDetails />}
                    />
                    <Route
                      path={Page.ACCOUNTABILITY_BUDDIES}
                      element={<AccountabilityBuddies />}
                    />
                    <Route path={Page.BUDDY_DETAILS} element={<Buddy />} />
                    <Route path={Page.SETTINGS} element={<Settings />} />
                    <Route
                      path={Page.USER_PROFILE}
                      element={<UserProfile />}
                    />
                    <Route path={Page.HOME} element={<Home />} />
                    <Route
                      element={<ProtectedRoute allowedRoles={["admin"]} />}
                    >
                      <Route path={Page.ADMIN_PANEL} element={<AdminPanel />} />
                    </Route>
                  </Route>
                </Route>
                <Route element={<RedirectSignInUpRoute />}>
                  <Route path={Page.SIGN_IN} element={<SignIn />} />
                  <Route path={Page.SIGN_UP} element={<SignUp />} />
                </Route>
              </Routes>
              {previousLocation && (
                <Routes>
                  <Route
                    element={
                      <ProtectedRoute allowedRoles={["admin", "user"]} />
                    }
                  >
                    <Route
                      path={Page.PERSONALITY_TEST_MODAL}
                      element={<AddPersonalityTestModal />}
                    />
                  </Route>
                </Routes>
              )}
              <GenericSnackbarController />
            </CssBaseline>
          </ThemeProvider>
        </LocalizationProvider>
      </OnlineProvider>
    </Provider>
  );
}

export default App;
