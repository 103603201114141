import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MobileStepper from "@mui/material/MobileStepper";
import WizardViewStepZero from "./WizardViewStepZero";
import WizardViewStepOne from "./WizardViewStepOne";

type OnboardingWizardViewProps = {
  activeStep: number;
  steps: string[];
  onBackClick: () => void;
  onStartClick: () => void;
  onSaveSuccess: () => void;
  savingUserProfile: boolean;
  setSavingUserProfile: (saving: boolean) => void;
  backgroundColor: string;
};

const OnboardingWizardView: React.FC<OnboardingWizardViewProps> = ({
  activeStep,
  steps,
  onBackClick,
  onStartClick,
  onSaveSuccess,
  savingUserProfile,
  setSavingUserProfile,
  backgroundColor,
}) => {
  const buttonPlaceholder = <Box sx={{ height: "36px", width: "64px" }} />;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "calc(100vh - 96px)",
        width: "100%",
        backgroundColor: backgroundColor,
        borderRadius: 8,
        padding: 4,
      }}
    >
      {activeStep === 0 && <WizardViewStepZero onStartClick={onStartClick} />}
      {activeStep === 1 && (
        <WizardViewStepOne
          onSaveSuccess={onSaveSuccess}
          setSavingUserProfile={setSavingUserProfile}
        />
      )}
      <MobileStepper
        variant="dots"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        sx={{
          width: "100%",
          backgroundColor: "transparent",
        }}
        nextButton={
          activeStep === 0 ? (
            buttonPlaceholder
          ) : (
            <Button size="small" type="submit" form="user-profile-form">
              {savingUserProfile ? <CircularProgress size={24} /> : "Save"}
            </Button>
          )
        }
        backButton={
          activeStep === 0 ? (
            buttonPlaceholder
          ) : (
            <Button size="small" onClick={onBackClick}>
              Back
            </Button>
          )
        }
      />
    </Box>
  );
};

export default OnboardingWizardView;
