import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

type UserProfileContainerProps = {
  children: React.ReactNode;
};

const UserProfileContainer: React.FC<UserProfileContainerProps> = ({
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 4,
          width: "80%",
        }}
      >
        <Typography variant="h6" paddingBottom={2}>
          User Profile
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default UserProfileContainer;
