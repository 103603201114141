import { auth } from "../../../config/firebase";
import { Severity } from "@beyondrealityapp/core/shared/constants";
import { showSnackbar } from "../../../features/snackbar";
import { object, string } from "yup";
import { useDispatch } from "react-redux";
import { User } from "@beyondrealityapp/core/user/classes";
import UserProfileFormView from "./UserProfileFormView";
import { useEffect } from "react";
import { useFetchUserQuery } from "../../../api/user";
import { useFormik } from "formik";
import { useParams } from "react-router";
import { UserProfileFormValues } from "@beyondrealityapp/core/user/types";
import { useImperativeHandle, useState } from "react";
import { useUpdateUserMutation } from "../../../api/user";

type UserProfileFormControllerProps = {
  onSaveSuccess?: () => void;
  setSavingUserProfile?: (saving: boolean) => void;
  hideSubmitButton?: boolean;
  ref?: React.Ref<{ submit: () => void }>;
};

const UserProfileFormController: React.FC<UserProfileFormControllerProps> = ({
  onSaveSuccess,
  setSavingUserProfile,
  hideSubmitButton,
  ref,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const { userId } = useParams();
  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
  } = useFetchUserQuery(userId ?? auth.currentUser?.uid);
  const [updateUser] = useUpdateUserMutation();
  const dispatch = useDispatch();

  const validationSchema = object({
    name: string().required("Name is required"),
    email: string().email("Invalid email").required("Email is required"),
  });

  const initialValues: UserProfileFormValues = {
    name: "",
    email: "",
    profilePicture: "",
    themes: [],
    sharedWithMe: [],
    sharedWithOthers: [],
  };

  const onSubmit = async (values: UserProfileFormValues) => {
    setSubmitting(true);
    setSavingUserProfile?.(true);
    updateUser(User.fromForm(values))
      .unwrap()
      .then(() => {
        dispatch(
          showSnackbar({
            severity: Severity.SUCCESS,
            message: "User updated successfully",
          })
        );
        onSaveSuccess?.();
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            severity: Severity.ERROR,
            message: error.message,
          })
        );
      })
      .finally(() => {
        setSubmitting(false);
        setSavingUserProfile?.(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useImperativeHandle(ref, () => ({
    submit: () => formik.handleSubmit(),
  }));

  const onImageModalClose = () => {
    setShowImageModal(false);
  };

  const onAddUserProfileImageClick = () => {
    setShowImageModal(true);
  };

  const onDeleteUserProfileImageClick = () => {
    formik.setFieldValue("profilePicture", "");
  };

  const setImageHandler = (image: string) => {
    formik.setFieldValue("profilePicture", image);
  };

  useEffect(() => {
    if (isUserError) {
      dispatch(
        showSnackbar({
          severity: Severity.ERROR,
          message:
            userError instanceof Error
              ? userError.message
              : "An error occurred while fetching the user",
        })
      );
    }
    if (user) {
      formik.setValues({
        name: user.name,
        email: user.email,
        profilePicture: user.profilePicture,
        themes: user.themes,
        sharedWithMe: user.sharedWithMe,
        sharedWithOthers: user.sharedWithOthers,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isUserError]);

  return (
    <UserProfileFormView
      formik={formik}
      isUserLoading={isUserLoading}
      submitting={submitting}
      hideSubmitButton={hideSubmitButton}
      showImageModal={showImageModal}
      onAddUserProfileImageClick={onAddUserProfileImageClick}
      onDeleteUserProfileImageClick={onDeleteUserProfileImageClick}
      onImageModalClose={onImageModalClose}
      setImageHandler={setImageHandler}
    />
  );
};

export default UserProfileFormController;
