import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import React from "react";

type AddGoalButtonProps = {
  onClick: () => void;
  variant?: "contained" | "outlined" | "text";
  buttonText?: string;
};

const AddGoalButton: React.FC<AddGoalButtonProps> = ({
  onClick,
  variant = "contained",
  buttonText = "Add Goal",
}) => {
  return (
    <Button
      startIcon={<AddIcon />}
      variant={variant}
      color="primary"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default AddGoalButton;
