import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select";

type ThemesMultiSelectViewProps = {
  error?: boolean;
  disabled?: boolean;
  helperText: string;
  fetchThemesErrorMessage: Error;
  isFetchThemesError: boolean;
  isLoading: boolean;
  value: string[];
  themes: string[];
  onChange: (
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => void;
};

const ThemesMultiSelectView: React.FC<ThemesMultiSelectViewProps> = ({
  error,
  disabled,
  helperText,
  fetchThemesErrorMessage,
  isFetchThemesError,
  isLoading,
  value,
  themes,
  onChange,
}) => {
  return (
    <FormControl margin="dense" fullWidth error={error}>
      <InputLabel id="themes" variant="standard">
        Themes
      </InputLabel>
      <Select
        id="themes"
        name="themes"
        label="Themes"
        variant="standard"
        placeholder="Select themes"
        value={value}
        onChange={onChange}
        disabled={isLoading || isFetchThemesError || disabled}
        renderValue={(selected) => selected.join(", ")}
        multiple
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
      >
        {isLoading && (
          <MenuItem disabled value="">
            Loading themes...
          </MenuItem>
        )}
        {isFetchThemesError && (
          <MenuItem disabled value="">
            {fetchThemesErrorMessage.message}
          </MenuItem>
        )}
        {!isLoading && themes.length === 0 && (
          <MenuItem disabled value="">
            Go to "Themes" to add a theme
          </MenuItem>
        )}
        {themes.map((theme) => (
          <MenuItem key={theme} value={theme}>
            <ListItemText primary={theme} />
            {value.includes(theme) && (
              <CheckBoxIcon color="primary" fontSize="small" />
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ThemesMultiSelectView;
