import { SelectChangeEvent } from "@mui/material";
import { useFetchThemesFromUserProfileQuery } from "../../../api/theme";
import ThemesMultiSelectView from "./ThemeMultiSelectView";
import React from "react";

type ThemeMultiSelectControllerProps = {
  onChange: (
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => void;
  value: string[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
};

const ThemeMultiSelectController: React.FC<ThemeMultiSelectControllerProps> = ({
  onChange,
  value,
  disabled,
  error,
  helperText,
}) => {
  const {
    data: themes,
    error: fetchThemesErrorMessage,
    isError: isFetchThemesError,
    isLoading,
  } = useFetchThemesFromUserProfileQuery(undefined);
  return (
    <ThemesMultiSelectView
      error={error}
      disabled={disabled}
      helperText={helperText ?? ""}
      fetchThemesErrorMessage={fetchThemesErrorMessage as Error}
      isFetchThemesError={isFetchThemesError}
      value={value}
      onChange={onChange}
      themes={themes ?? []}
      isLoading={isLoading}
    />
  );
};

export default ThemeMultiSelectController;
