import React from "react";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router";

type NavigateButtonProps = {
  page?: string;
  buttonText?: string;
};

const NavigateButtonController: React.FC<NavigateButtonProps> = ({
  page,
  buttonText,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (page) {
      navigate(page);
    } else {
      navigate(-1);
    }
  };
  return <NavigateButton onClick={onClick} text={buttonText || "Back"} />;
};

export default NavigateButtonController;
