import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteButton from "./DeleteButton";
import SubmitButton from "./SubmitButton";

type submitDeleteActionsProps = {
  submitting: boolean;
  deleting: boolean;
  allowDelete?: boolean;
  cancelHandler?: () => void;
  deleteHandler?: () => void;
  size?: "small" | "medium" | "large";
};

const SubmitDeleteActions: React.FC<submitDeleteActionsProps> = ({
  submitting,
  deleting,
  allowDelete = true,
  cancelHandler,
  deleteHandler,
  size = "medium",
}) => {
  return (
    <Box
      flexDirection="row-reverse"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flex: 1,
        marginBottom: 1,
      }}
    >
      <Box>
        {cancelHandler ? (
          <Button
            variant="text"
            sx={{
              marginRight: 1,
            }}
            onClick={cancelHandler}
            size={size}
          >
            Cancel
          </Button>
        ) : null}
        <SubmitButton submitting={submitting} size={size} />
      </Box>
      {deleteHandler && allowDelete ? (
        <DeleteButton deleteHandler={deleteHandler} deleting={deleting} />
      ) : null}
    </Box>
  );
};

export default SubmitDeleteActions;
